import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import Button from "../button/button";

import "./liftup.scss";

const Liftup = (props) => {
	const { data } = props;

	const blockClasses = [];

	const classes = `block block-liftup ${[...blockClasses]}`;

	return (
		<section className={classes}>
			<div className="wrap">
				{
					data.map((liftupData) => {
						let showText = false;
						if (liftupData.title || liftupData.description) showText = true;

						let figureClass = 'liftup__image';
						if (liftupData.highlight) figureClass += ' has-hightlight';

						return (
							<div class="liftup">
								{typeof liftupData.image === "object" &&
									<figure class={figureClass}>
										<GatsbyImage image={liftupData.image.childImageSharp.gatsbyImageData} alt="" loading="lazy" layout="fullWidth" fit="inside" />
										{liftupData.highlight &&
											<p>{liftupData.highlight}</p>
										}

										<Button className="liftup__button" url={liftupData.buttonUrl} text={liftupData.buttonText} />
									</figure>
								}

								{showText &&
									<div class="liftup__text">
										<h3>{liftupData.title}</h3>
										<p>{liftupData.description}</p>
									</div>
								}
							</div>
						)
					})
				}
			</div>
		</section>
	)
}

export default Liftup