import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import Button from "../button/button";

import "./grid-4.scss";

const Grid4 = (props) => {
	const { data } = props;
	const items = data.edges;

	const blockClasses = [];

	const classes = `block block-grid-4 ${[...blockClasses]}`;

	return (
		<section className={classes} id="ajankohtaista">
			<div className="wrap">
				<h2>Ajankohtaista</h2>

				<div class="grid-generic-4">
					{
						items.map((item) => {
							let newsItem = item.node

							return (
								<div class="grid-4-item">
									<a href={newsItem.url} target="_blank" rel="noopener noreferrer">
										<figure className="grid-4-item__image">
											<GatsbyImage image={newsItem.image.gatsbyImageData} alt="" />
											<Button className="grid-item__button" url={newsItem.url} text="Lue lisää" color="blue" arrow={true} />
										</figure>

										<div className="grid-4-item__text">
											<h3 className="grid-4-item__title">{newsItem.title}</h3>
											{(newsItem.text && newsItem.text.raw) && (
												<p className="grid-4-item__description">{renderRichText(newsItem.text)}</p>
											)}
										</div>
									</a>
								</div>
							)
						})
					}
				</div>
			</div>
		</section>
	)
}

export default Grid4