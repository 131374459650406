import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Map from "../../../images/image-map/jungmann-map-katajanokka-vector.svg";
import Compass from "../../../images/image-map/jungmann-map-compass.svg";

import "./image-map.scss";

const ImageMap = (props) => {
	const { data } = props;
	
	const blockClasses = ['has-background-color-cream'];

	const classes = `block block-image-map ${[...blockClasses]}`;

	return(
		<section className={classes} id="sijainti">
			<div className="wrap">
				<h2>{ data.title }</h2>
				<GatsbyImage image={data.image.childImageSharp.gatsbyImageData} alt="" as="figure" />
				<figure class="block-image-map__map">
					<img class="block-image-map__compass" src={Compass} alt="" width="134" height="29" loading="lazy" />
					<div class="block-image-map__actual-container">
						<img class="block-image-map__actual" src={Map} alt="" width="1283" height="684" loading="lazy" />
					</div>
				</figure>
			</div>
		</section>
	)
}

export default ImageMap