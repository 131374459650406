import React from "react"

import "./dataliftup.scss";

import Logo from "../../../images/jungmann-logo.svg"

const DataLiftup = (props) => {
	const { data } = props;
	
	const blockClasses = ['has-background-color-cream'];

	const classes = `block block-dataliftup ${[...blockClasses]}`;

	return(
		<section className={classes}>
			<div className="wrap">
				<img class="logo" src={Logo} alt="Jungmann logo" width="175" height="22" />
				<figure className="block-dataliftup__image"><img src={data.image} alt="Jungmann facade drawing" width="1360" height="207" loading="lazy" /></figure>
				<div class="block-dataliftup__data">
					{
						data.data.map((item) => {
							return(
								<figure class="data-item">
									<figcaption>{item.label}</figcaption>
									<div class="data-item__value">{item.value}</div>
								</figure>
							)
						})
					}
				</div>
			</div>
		</section>
	)
}

export default DataLiftup