import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import "./textimage-large.scss";

const TextImageLarge = (props) => {
	const { image, text } = props.data;
	
	const blockClasses = ['has-background-color-terracotta'];

	const classes = `block block-textimage-large ${[...blockClasses]}`;

	return(
		<section className={classes}>
			<div className="wrap">
				<div class="block-textimage-large__text" dangerouslySetInnerHTML={{ __html: text }}></div>
				<GatsbyImage image={image.childImageSharp.gatsbyImageData} alt="" as="figure" />
			</div>
		</section>
	)
}

export default TextImageLarge