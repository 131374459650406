import React from "react"
import { graphql } from "gatsby"
import loadable from "@loadable/component"

import Header from "../components/header/header"
import FooterBlock from "../components/footer/footerBlock"

import Seo from "../components/seo"
import Hero from "../components/blocks/hero/hero"
import Liftup from "../components/blocks/liftup/liftup"
import DataLiftup from "../components/blocks/dataliftup/dataliftup"
import TextImageLarge from "../components/blocks/textimage-large/textimage-large"
import ImageMap from "../components/blocks/image-map/image-map"
import ImageTextCollection from "../components/blocks/image-text-collection/image-text-collection"
import Grid4 from "../components/blocks/grid-4/grid-4"
import MultiContent from "../components/blocks/multicontent/multicontent"

import DataLiftupImage from "../images/jungmann-building-drawing-facade.svg"

const ImageTextCarousel = loadable(() =>
  import("../components/blocks/image-text-carousel/image-text-carousel")
)

export const query = graphql`
  query {
    allContentfulNewsItem(sort: {fields: createdAt, order: DESC}) {
      edges {
        node {
          title
          text {
            raw
          }
          url
          image {
            gatsbyImageData(width: 320, height: 320)
          }
        }
      }
    }
    contentfulPage(contentful_id: {eq: "67gMzpIkKNQkyxpRZRe4om"}) {
      menuItems {
        label
        url
      }
      title
      footer {
        address
        sections {
          order
          title
          content {
            title
            content {
              raw
            }
          }
        }
      }
    }
    palvelut: contentfulContentSection(contentful_id: {eq: "3suQiyWTKSyW9oawvKJmqQ"}) {
      title
      slug
      intro {
        raw
      }
      grid {
        title
        slug
        color
        image {
          gatsbyImageData(width: 820)
        }
        intro {
          raw
        }
        buttons {
          url
          label
          color
          arrow
        }
      }
    }
    textImageLarge: file(
      relativePath: { eq: "textimage-large/jungmann-entrance.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 820)
      }
    }
    imageHero: file(relativePath: { eq: "jungmann-hero-image.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, placeholder: BLURRED, quality: 46)
      }
    }
    imageHeroPortrait: file(
      relativePath: { eq: "jungmann-hero-image-portrait.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 400, placeholder: BLURRED)
      }
    }
    imageHeroPortrait400: file(
      relativePath: { eq: "jungmann-hero-image-portrait.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 400, placeholder: BLURRED)
      }
    }
    imageLiftup1: file(
      relativePath: { eq: "liftup/jungmann-asumista-katajanokan-sydamessa.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600)
      }
    }
    imageLiftup2: file(
      relativePath: { eq: "liftup/jungmann-vuokra-asunnot.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600)
      }
    }
    imageLiftup3: file(relativePath: { eq: "liftup/jungmann-palvelut.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 600)
      }
    }
    imageTextImageLeft: file(
      relativePath: { eq: "carousel/jungmann-urbaani-studiokoti.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 700)
      }
    }
    imageTextImageBottom: file(
      relativePath: { eq: "jungmann-hero-image.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920)
      }
    }
    carousel1: file(
      relativePath: { eq: "carousel/Puroplan_ankkurikatu5_Asunto_C59_01.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 540)
      }
    }
    carousel2: file(
      relativePath: { eq: "carousel/Puroplan_ankkurikatu5_Asunto_A28_01.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 540)
      }
    }
    carousel3: file(
      relativePath: { eq: "carousel/Puroplan_ankkurikatu5_Asunto_A16_01.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 540)
      }
    }
    carousel4: file(
      relativePath: { eq: "carousel/Puroplan_Ankkurikatu5_A7_01.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 540)
      }
    }
    carousel5: file(
      relativePath: { eq: "carousel/Puroplan_Ankkurikatu5_A7_02.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 540)
      }
    }
    carousel6: file(
      relativePath: { eq: "carousel/Puroplan_Ankkurikatu5_A7_03.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 540)
      }
    }
    carousel7: file(relativePath: { eq: "carousel/A7-WC-3D-kuva.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 540)
      }
    }
    carousel8: file(relativePath: { eq: "carousel/Asuntokaytava-3D.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 540)
      }
    }
    carousel9: file(relativePath: { eq: "carousel/Aula-3D-havainnekuva.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 540)
      }
    }
    imageMapImage: file(
      relativePath: { eq: "image-map/jungmann_ankkurikatu5_014.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920)
      }
    }
    textCollectionLeftImage: file(
      relativePath: {
        eq: "image-text-collection/jungmann_ankkurikatu5_018.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 820)
      }
    }
    textCollectionRightImage: file(
      relativePath: {
        eq: "image-text-collection/jungmann-ankkurikatu5_042.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 700)
      }
    }
    textCollectionLeftRightImage: file(
      relativePath: {
        eq: "image-text-collection/jungmann-historical-blackwhite-varastorakennus.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 540)
      }
    }
    textCollectionLargeImage: file(
      relativePath: {
        eq: "image-text-collection/jungmann-katajanokka-at-night.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920)
      }
    }
    textCollectionLargeImagePortrait: file(
      relativePath: {
        eq: "image-text-collection/jungmann-katajanokka-at-night-portrait.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 400)
      }
    }
    textCollectionLargeImagePortrait400: file(
      relativePath: {
        eq: "image-text-collection/jungmann-katajanokka-at-night-portrait.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 400)
      }
    }
  }
`

const IndexPage = ({ data }) => {
  const liftUpData = [
    {
      highlight: "Monimuotoista asumista ja elämistä katajanokan sydämessä",
      image: data.imageLiftup1,
    },
    {
      title: "Vuokra-asunnot",
      description: "Makoisaa asumista makasiinirakennuksessa – asetu kodiksi!",
      buttonUrl: "#vuokra-asunnot",
      buttonText: "Tutustu",
      image: data.imageLiftup2,
    },
    {
      title: "Palvelut",
      description: "Kokonainen kortteli täynnä palveluita ja urbaania elämää.",
      buttonUrl: "#palvelut",
      buttonText: "Tutustu",
      image: data.imageLiftup3,
    },
  ]

  const dataLiftupData = {
    image: DataLiftupImage,
    data: [
      { label: "Rakennusvuosi", value: "1907" },
      { label: "Valmistuminen", value: "2022" },
      { label: "Liiketiloja", value: "10" },
      { label: "Asuinhuoneistoja", value: "124" },
      { label: "1–4 huoneen asuntoja", value: "25–108m²" },
      { label: "Kattoterassi", value: "500 m²" },
      { label: "Etäisyys kauppatorille", value: "5 min" },
      { label: "Etäisyys rautatieasemalle", value: "15 min" },
      { label: "Raitiovaunulinja", value: "4, 5" },
    ],
  }

  const textImageLargeData = {
    text: `<p>Jungmann luo Katajanokan sydämeen viihtyisän keitaan, kohtaamispaikan jossa voi nauttia elämästä täysin siemauksin.</p>
<p>Koko naapurustoa elävöittävien liikkeiden ja palveluiden lisäksi taloon tulee moderneja täyden palvelun asuntoja sekä korkeatasoinen huoneistohotelli.</p>`,
    image: data.textImageLarge,
  }

  const imageTextCarouselData = {
    title: "Nykyaikaista asumista art&nbsp;nouveau -ympäristössä",
    text: "Jykevissä paikan päällä käsin muuratuissa rakennuksissa on täysin oma tunnelmansa. Niissä on helppo aistia pysyvyyden tunnetta ja historiallista arvokkuutta, joka on nykyään harvinaista – ja siksi niin arvostettua.",
    textImageText: {
      left: {
        image: data.imageTextImageLeft,
        caption:
          "Urbaani studiokoti kohtaa kansallis&shy;romanttisen taideteoksen",
      },
      text: {
        text: `<p>Jungmannin asunnot ovat kaikki yksilöllisiä sekä korkealaatuisia. Huoneistokohtainen jäähdytys tekee asumisesta miellyttävää vuodenajasta riippumatta. Täydellisesti varustellut keittiöt upeilla kivitasoilla ja integroiduilla Siemensin kodinkoneilla yhdistettynä tyylikkäisiin parketti- sekä kivilattioihin tuovat elämiseen laatua.</p>
          <p>Suuremmista asunnoista löytyy omat saunat ja niiden kylpyhuoneista myös ammeet. Talvipuutarha ulkoterasseineen merinäköalalla lisää asukkaiden viihtyvyyttä, kuten myös Tesla -yhteiskäyttöauto parkkihallissa.</p>
          <p>Concierge-palvelu tekee arjesta huolettomamman ja sen kautta voi varata esim. asunnon siivouksen, näköalasaunan tai ulkoporealtaan.</p>`,
        button: {
          text: "Tutustu vuokra-asuntoihin",
          url: "https://kodisto.fi/",
        },
      },
      bottom: {
        image: data.imageTextImageBottom,
        caption: "(esimerkkiAsunnon Pohjakuva)",
      },
    },
    carousel: [
      data.carousel1,
      data.carousel2,
      data.carousel3,
      data.carousel4,
      data.carousel5,
      data.carousel6,
      data.carousel8,
      data.carousel9,
      data.carousel7,
    ],
  }

  const imageMapData = {
    title: "Sijainti",
    image: data.imageMapImage,
  }

  const imageTextCollectionData = {
    title: "Jugend&shy;korttelien romantiikkaa",
    text: "Katajanokan vanhan puolen rakennuskanta on harvinaisen yhtenäinen kokonaisuus kansallisromanttista arkkitehtuuria.",
    left: {
      html: `<p>Sadunomaisten muotojen ja värien runsautta pursuavan jugend-kaupunginosan, rosoista työläisromantiikkaa uhkuvan tavaraliikennesataman ja entisen lääninvankila-alueen ympäröimä makasiinirakennus on ehtinyt vuosikymmenten saatossa nähdä monenlaista elämää ja kuulla mitä uskomattomimpia tarinoita.</p>
      <p>Nyt kirjoitetaan uusi luku alueen historiaa, kun Kruunuvuorenkatu 4:ssä sijaitseva vuonna 1907 valmistunut vanha makasiinirakennus uudistetaan sen ominaispiirteitä kunnioittaen.</p>`,
      image: data.textCollectionLeftImage,
    },
    right: {
      image: data.textCollectionRightImage,
    },
    leftRight: {
      image: data.textCollectionLeftRightImage,
      html: `<h3>Hökkelikylästä arvoalueeksi</h3>
      <p>Varastorakennusten ja hökkelien täyttämästä joutomaasta kehittyi 1800-luvun puolivälin jälkeen yksi Helsingin arvostetuimmista ja kulttuurihistoriallisesti merkityksellisimmistä asuinalueista. Alueen maamerkki, Uspenskin katedraali valmistui 1868 ja viereinen asuntoalue, Katajanokan vanha puoli, rakennettiin pääosin 1900-luvun alussa. Ympäröivän meren läheisyys sekä näkymät Helsingin historialliseen keskustaan luovat alueelle ainutlaatuisen tunnelman</p>`,
    },
    largeImage: data.textCollectionLargeImage,
    largeImagePortrait: data.textCollectionLargeImagePortrait,
    largeImagePortrait400: data.textCollectionLargeImagePortrait400,
  }

  return (
    <>
      <Header />

      <main>
        <Seo title="Home" />
        <Hero
          image={data.imageHero}
          imagePortrait={data.imageHeroPortrait}
          imagePortrait400={data.imageHeroPortrait400}
          text="Uusi urbaani palvelukortteli on avautunut."
          textSmall="Tervetuloa viihtymään - aamusta iltaan!"
        />
        <Liftup data={liftUpData} />
        <DataLiftup data={dataLiftupData} />
        <TextImageLarge data={textImageLargeData} />
        <MultiContent data={data.palvelut} />
        <ImageTextCarousel data={imageTextCarouselData} />
        <ImageMap data={imageMapData} />
        <ImageTextCollection data={imageTextCollectionData} />
        <Grid4 data={data.allContentfulNewsItem} />
      </main>

      <FooterBlock data={data.contentfulPage.footer} />
    </>
  )
}

export default IndexPage
