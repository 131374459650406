import React from "react"
import { GatsbyImage, withArtDirection } from "gatsby-plugin-image"
import Logo from "../../../images/jungmann-logo-inverted.svg"
import "./hero.scss";

const Hero = (props) => {
	const { image, imagePortrait, imagePortrait400 } = props;

	const blockClasses = [];

	const classes = `block block-hero ${[...blockClasses]}`;

	const images = withArtDirection(image.childImageSharp.gatsbyImageData, [
		{
			media: "(max-width: 600px) and (min-width: 401px)",
			image: imagePortrait.childImageSharp.gatsbyImageData,
		},
		{
			media: "(max-width: 400px)",
			image: imagePortrait400.childImageSharp.gatsbyImageData,
		},
	])

	return (
		<section className={classes}>
			<div className="wrap">
				<img src={Logo} alt="Jungmann logo" width="175" height="22" class="logo-inverted" />
			</div>
			<div className="wrap">
				<h1>{props.text}
					{props.textSmall && (
						<span class="block-hero__small-text">
							{props.textSmall}
						</span>
					)}
				</h1>
			</div>
			<GatsbyImage class="block-hero__background-image" image={images} alt="" as="figure" loading="eager" objectFit="cover" />
		</section>
	)
}

export default Hero