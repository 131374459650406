import React from "react"
import { GatsbyImage, withArtDirection } from "gatsby-plugin-image"

import "./image-text-collection.scss";

const ImageTextCollection = (props) => {
	const { data } = props;
	
	const blockClasses = ['has-background-color-cream'];

	const classes = `block block-image-text-collection ${[...blockClasses]}`;

	const images = withArtDirection(data.largeImage.childImageSharp.gatsbyImageData, [
    {
      media: "(max-width: 600px) and (min-width: 401px)",
      image: data.largeImagePortrait.childImageSharp.gatsbyImageData,
    },
    {
      media: "(max-width: 400px)",
      image: data.largeImagePortrait400.childImageSharp.gatsbyImageData,
    },
  ])

	return(
		<section className={classes} id="historia">
			<div className="wrap">
				<h2 dangerouslySetInnerHTML={{ __html: data.title }}></h2>
				<p className="description has-font-style-body-medium">{ data.text }</p>

				<div class="text-image-image">
					<div className="text-image-image__left">
						<div class="text has-font-style-body-small" dangerouslySetInnerHTML={{ __html: data.left.html }}></div>
						<figure>
							<GatsbyImage image={data.left.image.childImageSharp.gatsbyImageData} alt="" />
						</figure>
					</div>

					<div class="text-image-image__right">
						<figure>
							<GatsbyImage image={data.right.image.childImageSharp.gatsbyImageData} alt="" />
						</figure>
					</div>
				</div>

				<div class="left-right">
					<figure>
						<GatsbyImage image={data.leftRight.image.childImageSharp.gatsbyImageData} alt="" />
						<figcaption>Sundström Eric / Helsingin kaupunginmuseo</figcaption>
					</figure>
					<div class="text" dangerouslySetInnerHTML={{ __html: data.leftRight.html }}></div>
				</div>

				<GatsbyImage class="large-image" image={images} alt="" as="figure"/>
			</div>
		</section>
	)
}

export default ImageTextCollection